import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import { CreditCardTopPicks as TopPicks } from "components/content/top-picks"
import * as Clickable from "components/clickable"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"
import { CreditCardDisclaimer } from "components/content/content-disclaimer"
import { ListHeaderProvider } from "components/content/list-header"
import { compileSchemaOrgQa } from "components/schema"
import ReactPlayer from "react-player"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import ReturnLinkChain from "components/content/return-link-chain"
import * as styles from "./styles.module.scss"
import FeaturedIn from "components/content/featured-in"
import { ArrowForwardIos } from "@mui/icons-material"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const CreditCardsLandingPageView = (props: { indexPage?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")
  const isTablet = !useMediaQuery("(min-width:1024px)")

  const topSection = () => {
    return !isTablet ? (
      <div className={styles.topSectionContainerDesktop}>
        <div className={styles.topSectionTextContentContainerDesktop}>
          <Typography.H1>
            <div style={{ color: "#00AFC5" }}>
              Get a{" "}
              <span className={styles.headingHighlightContainer}>
                <span className={styles.headingHighlightText}>better</span>
                <div className={styles.headingHighlightBackground} />
              </span>
              <br />
              credit card
            </div>
          </Typography.H1>
          <div style={{ height: "40px" }} />
          <Typography.P comp="external-landing-page-top-text">
            <span style={{ color: "#4D4D4D" }}>From cash back to upscale travel rewards, we bring you top matches from top providers. Upgrade your credit card today!</span>
          </Typography.P>
          <div className={styles.getStartedContainer}>
            <Clickable.Text
              comp={1}
              component={Link}
              to={"/credit-cards/instructions/"}
              className="landing-page-button"
              style={{ width: "100%" }}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "CREDIT_CARDS", productCategory: "CREDIT_CARDS" })
              }}
            >
              <div style={{ margin: "auto" }}>
                Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
              </div>
            </Clickable.Text>
            <Typography.P comp="small">
              <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
            </Typography.P>
          </div>
        </div>
        <div className={styles.topSectionImageContentContainerDesktop}>
          <LazyLoadImage src="/images/credit_cards_landing/credit-card-top-image-desktop.png" className={styles.topSectionImageStyleDesktop} />
        </div>
      </div>
    ) : (
      <div className={styles.topSectionContainerTablet}>
        <div className={styles.topSectionTextContainerTablet}>
          <Typography.H1>
            <div style={{ color: "#00AFC5", textAlign: "center" }}>
              Get a{" "}
              <span className={styles.headingHighlightContainer}>
                <span className={styles.headingHighlightText}>better</span>
                <div className={styles.headingHighlightBackground} />
              </span>
              <br />
              credit card
            </div>
          </Typography.H1>
        </div>
        <LazyLoadImage src="/images/credit_cards_landing/credit-card-top-image-mobile.png" style={{width: "100%", height: "100%"}} />
        <div className={styles.topSectionTextContainerTabletSecondary}>
          <Typography.P comp="external-landing-page-top-text">
            <div style={{ textAlign: "center" }}>From cash back to upscale travel rewards, we bring you top matches from top providers. Upgrade your credit card today!</div>
          </Typography.P>
          <div className={styles.getStartedContainer}>
            <Clickable.Text
              comp={1}
              component={Link}
              to={"/credit-cards/instructions/"}
              className="landing-page-button"
              style={{ width: "100%" }}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "CREDIT_CARDS", productCategory: "CREDIT_CARDS" })
              }}
            >
              <div style={{ margin: "auto" }}>
                Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
              </div>
            </Clickable.Text>
            <Typography.P comp="small">
              <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
            </Typography.P>
          </div>
        </div>
      </div>
    )
  }

  const sectionTextDivider = (heading: string, text: string, style?: React.CSSProperties) => {
    return (
      <div className={styles.sectionTextDivider} style={{ ...style }}>
        <Typography.H2 comp="external-landing-page-section-heading">{heading}</Typography.H2>
        <Typography.P comp="large">{text}</Typography.P>
      </div>
    )
  }

  const joinerDot = () => <circle className={styles.joinerDotStyle} />

  const contentBox = (imageUrl: string, heading: string, text: string, index: number) => {
    return (
      <div className={styles.contentBoxContainer}>
        <circle className={styles.contentBoxNumberContainer}>
          <div className={styles.contentBoxNumberText}>{index}</div>
        </circle>
        <div className={styles.contentBoxContentContainer}>
          <LazyLoadImage src={imageUrl} style={{height: "75px"}} />
          <div style={{ height: "40px" }} />
          <Typography.P comp="medium-bold">
            <div style={{ marginBottom: "10px" }}>{heading}</div>
          </Typography.P>
          <Typography.P comp="small">{text}</Typography.P>
        </div>
      </div>
    )
  }

  const simplifySearchSection = () => {
    return (
      <div style={{ display: "flex", flexDirection: isWide ? "row" : "column", justifyContent: "space-between", alignItems: "stretch", marginTop: "60px" }}>
        {contentBox("/images/icon_card.png", "Tell us the basics", "Answer a few simple questions about your credit card and banking preferences", 1)}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {isWide && joinerDot()}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {contentBox("/images/icon_search.png", "We search for a better deal", "See what your provider will offer you to stay, and compare leading offers from across the market", 2)}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {joinerDot()}
        {isWide && joinerDot()}
        <div style={isWide ? { width: "11px" } : { height: "16px" }} />
        {contentBox("/images/icon_save.png", "Choose to stay, or go", "Stay with your provider if you're happy with their deal, or go with a better offer and start saving", 3)}
      </div>
    )
  }

  const assessmentFactBox = (heading: string, text: string) => {
    return (
      <div className={styles.assessmentFactBox}>
        <Typography.H3 comp="benefit">{heading}</Typography.H3>
        <div style={{ height: "14px" }} />
        <Typography.P comp="content-p">
          <span style={{ color: "#4D4D4D" }}>{text}</span>
        </Typography.P>
      </div>
    )
  }

  const assessLoansSection = () => {
    return (
      <div style={{ marginTop: isWide ? "50px" : "35px" }}>
        <Typography.P comp="large">
          <div style={{ textAlign: "center" }}>In our evaluation of the best credit cards, we consider multiple factors. These include whether they offer features that cater to the diverse priorities of cardholders, in addition to their preferences. These priorities range from earning rewards to reducing interest costs. Additionally, we strive to present cards suitable for different credit levels and price ranges whenever possible, so you can find the perfect card for all your needs.</div>
        </Typography.P>
        <div className={styles.assessmentFactBoxesGrid}>
          {assessmentFactBox("124", "credit cards and offers analyzed")}
          {assessmentFactBox("50+", "providers and lenders evaluated")}
          {assessmentFactBox("8", "cards matched per person on avg")}
          {assessmentFactBox("2,500+", "users have found a better deal")}
        </div>
      </div>
    )
  }

  const noCreditCard = () => {
    return (
      <div className={styles.noCreditCardContainer}>
        <div style={{ padding: isWide ? "36px" : "28px" }}>
          <Typography.H3 comp="benefit">Don't have a credit card?</Typography.H3>
          <div style={{ height: "30px" }} />
          <Typography.P comp="large">
            <div style={{ color: "#4D4D4D" }}>
              Choosing the perfect fit can be quite overwhelming.{" "}
              <Link to="/credit-cards" style={{ textDecoration: "underline", color: "#F84283" }}>
                Explore our extensive collection
              </Link>{" "}
              of credit cards to find cards with the rewards and perks you want.
            </div>
          </Typography.P>
        </div>
        <LazyLoadImage src="/images/credit_cards_landing/dont-have-credit-card-image.png" className={styles.noCreditCardImage} />
      </div>
    )
  }

  const tickLine = (text: string) => {
    return (
      <div className={styles.tickLine}>
        <LazyLoadImage src="/images/icon_tick_pink.png" style={{ width: "30px", marginRight: "22px" }} />
        <Typography.P comp="large">
          <div style={{ color: "#4D4D4D" }}>{text}</div>
        </Typography.P>
      </div>
    )
  }

  const newToStayOrGo = () => {
    return (
      <div className={styles.newToStayOrGoContainer}>
        <Typography.H3 comp="benefit">New to Stay or Go?</Typography.H3>
        <div style={{ height: "30px" }} />
        <Typography.P comp="large">
          <div style={{ color: "#4D4D4D" }}>Sign up with us to receive the following benefits:</div>
        </Typography.P>
        {tickLine("Access a better deal without needing to change banks")}
        {tickLine("Explore what you could save by changing banks")}
        {tickLine("Get an alert when your deal is no longer competitive")}
      </div>
    )
  }

  return (
    <div>
      <div className={styles.pageContentContainer}>
        {topSection()}
        {sectionTextDivider("We do the homework. You reap the benefits, for free.", "You deserve the best in credit cards, and we can help you find a better match so you can start saving without switching with 3 easy steps.", { marginTop: isWide ? "128px" : "65px" })}
        {simplifySearchSection()}
        {sectionTextDivider("How we assess top cards", "In a nutshell, we crunch a lot of numbers", { marginTop: isWide ? "146px" : "80px" })}
        {assessLoansSection()}
        <div className={styles.lastContentSectionContainer}>
          {noCreditCard()}
          {!isWide && <div style={{ height: "40px" }} />}
          {newToStayOrGo()}
        </div>
        <FeaturedIn />
      </div>
    </div>
  )
}

const CreditCardsLandingPage = () => {
  return (
    <div className="container-center">
      <div className="home-container">
        <div>
          <CreditCardsLandingPageView />
        </div>
      </div>
    </div>
  )
}

export default CreditCardsLandingPage
