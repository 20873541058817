import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const FeaturedIn = () => {
  const isWide = useMediaQuery("(min-width:768px)")
  const isTablet = !useMediaQuery("(min-width:1024px)")

  const logos = [
    {
      name: "Australian Financial Review",
      src: "/images/featured_in/afr.png",
    },
    {
      name: "FinTech Australia",
      src: "/images/featured_in/fintech-australia.png",
    },
    {
      name: "The Canberra Times",
      src: "/images/featured_in/the-canberra-times.png",
    },
    {
      name: "Yahoo Finance",
      src: "/images/featured_in/yahoo-finance.png",
    },
  ]

  const mapLogosToDiv = () => {
    const itemsPerRow = !isTablet ? 4 : 2
    const rows: JSX.Element[] = []
    for (let r = 0; r <= logos.length / itemsPerRow; r++) {
      const rowItems: JSX.Element[] = []
      for (let c = 0; c < itemsPerRow; c++) {
        if (r * itemsPerRow + c >= logos.length) {
          break
        }
        const l = logos[r * itemsPerRow + c]
        rowItems.push(<LazyLoadImage src={l.src} title={l.name} alt={`${l.name} logo`} style={{ width: isWide ? "180px" : "120px", marginTop: "auto", marginBottom: "auto" }} key={`featured-in-item-${r}-${c}`} />)
      }
      rows.push(
        <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "30px" }} key={`featured-in-row-${r}`}>
          {rowItems}
        </div>,
      )
    }
    return rows
  }

  return (
    <div style={{ marginTop: "100px", textAlign: "center", width: "100%" }}>
      <div style={{ marginBottom: "30px" }}>
        <Typography.H2>Featured in</Typography.H2>
      </div>
      {mapLogosToDiv()}
    </div>
  )
}

export default FeaturedIn
